import "../../styles/section.css";
import "./experiencia.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Vimeo from "@u-wave/react-vimeo";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/SectionLanding";
import Logo from "../../svg/begin-logo.svg";
import HeadsetIcon from "../../svg/icons/headset.svg";

import Subrayado3 from "../../svg/subrayados/subrayado03.svg";

export default function IndexPage() {
	const { t } = useTranslation();
	const mobileOrTablet = function () {
		let check = false;
		(function (a) {
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
					a
				) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
					a.substr(0, 4)
				)
			)
				check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	};

	const [currentSection, setCurrentSection] = React.useState(0);
	const [pause, setPause] = React.useState(true);

	const chopSections = [18.9, 39, 56.3, 76.5, 92, 111.5];
	let currentChop = chopSections[Math.floor((currentSection - 1) / 2)];

	const nextSection = () => {
		if (currentSection === 0) {
			setCurrentSection(1);
			setTimeout(() => {
				setPause(false);
			}, 950);
			setTimeout(() => setCurrentSection(2), 4000);
		} else {
			setPause(false);
			setCurrentSection(currentSection + 1);
		}
	};

	const sections = [
		<div className="expIntro">
			{/* <StaticImage
        src={`../../images/exp/bg1.jpg`}
        className="expBg backgroundImg"
        alt="Section background"
        layout="fullWidth"
      /> */}
			<div className="topLogo">
				<Logo />
				<h3>
					<Trans>Presenta</Trans>
				</h3>
			</div>
			<div className="expContent">
				<div className="expTitle">
					<h1>
						<Trans>Vuelve al origen</Trans>
					</h1>
					<button onClick={nextSection} className="playButton">
						Play
					</button>
				</div>
				<div className="expCredits">
					<h2>
						<Trans i18nKey="creditosTitle">
							Un vídeo original de:
							<br />
							Begin Studios / Javier Monzó & Miguel Yubero
						</Trans>
					</h2>

					<h2>
						<Trans i18nKey="creditosBody">
							Dirección - Javi Monzó & Miguel Yubero &emsp; Dirección de
							fotografía - Jesús Ponce &emsp; Foquista Mario Aparicio &emsp; SFX
							- Rubén Segovia
							<br />
							Dirección de arte - Marta Cazalla &emsp; Asistente de arte -
							Gabriel Ibáñez
							<br />
							Estilismo - Sara Beyler &emsp; Maquillaje - María Lara &emsp;
							Casting - Paula Pino, Elena Orts, John Munita
						</Trans>
					</h2>
				</div>
			</div>
		</div>,
		<div>
			<div className="expBg backgroundImg headsetWarningBg"></div>
			<div className="expContent headsetPrompt">
				<HeadsetIcon className="headsetIcon" />
				<h1>
					<Trans>Para una experiencia inmersiva</Trans>
				</h1>
				<h3>
					<Trans>Usa auriculares</Trans>
				</h3>
			</div>
		</div>,
		<div className="emptyDivVidOverlay" />,
		<div>
			<StaticImage
				src={`../../images/exp/bg2.jpg`}
				className="expBg backgroundImg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="expContent">
				<SectionLanding
					title={t("Un mundo demasiado rápido")}
					subtitle={t("El cambio necesario")}
					orbitalText={t("Conecta")}
				>
					<button onClick={nextSection} className="playButton">
						Play
					</button>
				</SectionLanding>
			</div>
		</div>,
		<div className="emptyDivVidOverlay" />,
		<div>
			<StaticImage
				src={`../../images/exp/bg3.jpg`}
				className="expBg backgroundImg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="expContent">
				<SectionLanding
					title={t("Somos reales no perfectos")}
					subtitle={t("Ser tú mismo es tu mejor versión")}
					orbitalText={t("Reales")}
					section="compromiso"
				>
					<button onClick={nextSection} className="playButton">
						Play
					</button>
				</SectionLanding>
			</div>
		</div>,
		<div className="emptyDivVidOverlay" />,
		<div>
			<StaticImage
				src={`../../images/exp/bg4.jpg`}
				className="expBg backgroundImg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="expContent">
				<SectionLanding
					title={t("Begin es cuidarte disfrutando")}
					subtitle={t("Conecta con tu verdadera esencia")}
					orbitalText={t("Cuidarte")}
				>
					<button onClick={nextSection} className="playButton">
						Play
					</button>
				</SectionLanding>
			</div>
		</div>,
		<div className="emptyDivVidOverlay" />,
		<div>
			<StaticImage
				src={`../../images/exp/bg5.jpg`}
				className="expBg backgroundImg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="expContent">
				<SectionLanding
					title={t("Bienvenidos a la gastronomía sostenible")}
					subtitle={t("El cambio necesario")}
					orbitalText={t("Sostenibilidad")}
					section="reserva"
				>
					<button onClick={nextSection} className="playButton">
						Play
					</button>
				</SectionLanding>
			</div>
		</div>,
		<div className="emptyDivVidOverlay" />,
		<div>
			<StaticImage
				src={`../../images/exp/bg6.jpg`}
				className="expBg backgroundImg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="expContent">
				<SectionLanding
					title={t("La evolución natural")}
					subtitle={t("Creemos en un mundo mejor")}
					orbitalText={t("Naturaleza")}
					section=""
				>
					<button onClick={nextSection} className="playButton">
						Play
					</button>
				</SectionLanding>
			</div>
		</div>,
		<div className="emptyDivVidOverlay" />,
		<div className="expIntro">
			<StaticImage
				src={`../../images/exp/bg1.jpg`}
				className="expBg backgroundImg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="topLogo">
				<Logo />
				<h3>
					<Trans>Presenta</Trans>
				</h3>
			</div>
			<div className="expContent">
				<div className="expTitle">
					<h1>
						<Trans>Sobre nosotros</Trans>
					</h1>
					<Link to="/compromiso" className="finalLink">
						<h2>
							<Trans>Compromiso</Trans>
						</h2>
						<Subrayado3 />
					</Link>
				</div>
				<div className="expCredits">
					<h2>
						<Trans i18nKey="creditosTitle">
							Un vídeo original de:
							<br />
							Begin Studios / Javier Monzó & Miguel Yubero
						</Trans>
					</h2>

					<h2>
						<Trans i18nKey="creditosBody">
							Dirección - Javi Monzó & Miguel Yubero &emsp; Dirección de
							fotografía - Jesús Ponce &emsp; Foquista Mario Aparicio &emsp; SFX
							- Rubén Segovia
							<br />
							Dirección de arte - Marta Cazalla &emsp; Asistente de arte -
							Gabriel Ibáñez
							<br />
							Estilismo - Sara Beyler &emsp; Maquillaje - María Lara &emsp;
							Casting - Paula Pino, Elena Orts, John Munita
						</Trans>
					</h2>
				</div>
			</div>
		</div>,
	];

	const videoChopper = ({ seconds }) => {
		// console.log("Time: " + seconds + " - Next chop at: " + currentChop)
		if (seconds > currentChop) {
			setCurrentSection(currentSection + 1);
			setTimeout(() => setPause(true), 600);
			if (mobileOrTablet() && currentSection + 2 < sections.length) {
				setTimeout(() => {
					setCurrentSection(currentSection + 2);
					setPause(false);
				}, 8000);
			}
		}
	};

	// console.log("Current section: " + currentSection)
	// console.log("Next pause at: " + currentChop)
	// console.log(sections.length)

	return (
		<Layout>
			<Seo title={t("Experiencia")} />
			<StaticImage
				src={`../../images/experiencia.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
				section="experiencia"
			/>
			<div className="landingSection">
				<SectionLanding
					title={
						<Trans i18nKey="experienciaLandingTitle">
							¿Listos para <i>volver al&nbsp;origen</i>?
						</Trans>
					}
					subtitle={t("Acompáñanos en este viaje")}
					orbitalText={t("Experiencia")}
				/>
			</div>

			<div className="section expSection">
				<Vimeo
					video="716009877"
					controls={false}
					className="embedVideo"
					onTimeUpdate={videoChopper}
					paused={pause}
					keyboard={false}
					start={0}
				/>
				<SwitchTransition mode="out-in">
					<CSSTransition key={currentSection} timeout={300} classNames="fade">
						{sections[currentSection]}
					</CSSTransition>
				</SwitchTransition>

				{/* <div>
          <div className="sectionLanding">
            <div>
              <div>
                <Floritura className="floritura" />
                <p>
                  <Trans>Proteínas</Trans>
                </p>
                <h1>
                  <Trans>Bienestar animal</Trans>
                </h1>
                <p>
                  <Trans>
                    Trabajamos de la mano con ganaderos que cuidan y evitan el
                    sufrimiento animal para así garantizar su bienestar
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div> */}
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
